import React from 'react'
import "../css/AllQuiz.css"
import "../css/All.css"

const About = () => {
  return (
    <div className="container011 color_about ">
        <div className="otte figos">
        <h1 className="figod">About Us</h1>
        </div>
        <hr />
       <div className="marall">
       <header >
  <h1>About PlayZila</h1>
  <p>Welcome to PlayZila, your go-to destination for engaging and informative question-answer content! At PlayZila, we're passionate about providing a platform where users can explore a wide range of topics, test their knowledge, and learn something new every day.</p>
</header>

<main>
  <section id="mission">
    <h2>Our Mission</h2>
    <p>Our mission at PlayZila is to create an inclusive and interactive community where users can discover, share, and engage with high-quality question-answer content. We strive to empower individuals to expand their knowledge and enhance their problem-solving skills through our carefully curated quizzes and informative articles.</p>
  </section>

  <section id="offer">
    <h2>What We Offer</h2>
    <ul>
      <li>Diverse Content: From trivia quizzes to educational quizzes, we offer a diverse range of content catering to various interests and age groups.</li>
      <li>Engaging Experience: Our user-friendly interface ensures a seamless and enjoyable experience for all our visitors.</li>
      <li>Quality Assurance: Each question-answer set is meticulously researched and reviewed to maintain the highest standards of accuracy and relevance.</li>
    </ul>
  </section>

  <section id="contact">
    <h2>Contact Us</h2>
    <p>Have questions, suggestions, or feedback? We'd love to hear from you! Feel free to reach out to us at Contact us.</p>
  </section>

  <section id="ads">
    <h2>Advertisements</h2>
    <p>PlayZila is supported by advertisements to help us continue providing free access to our content. We strive to ensure that our advertisements are relevant, non-intrusive, and comply with Google AdSense policies.</p>
  </section>

</main>

<footer>
  <p>Thank you for choosing PlayZila! We hope you enjoy your experience exploring, learning, and engaging with our content.</p>
</footer>
       </div>

    </div>
  )
}

export default About