export const QuizData  = [
    {   
          question : " Which social media platform is known for its disappearing photos and videos feature?",
          options : ["Facebook", "Instagram", "Snapchat", "Twitter"],
          answer : 2
    }, 
    {
        question : "Which loop construct in Java is best suited when the number of iterations is known? ",
        options :["for loop", "while loop", "do-while loop", "break statement "],
        answer: 1
    }


]


