import React from 'react';
import { Link } from 'react-router-dom';
import "../css/letstart.css";
import conis from "../img/conis.png"; // Import the image file

const LetStart = () => {
  return (
    <div className='containert'>
      <div className="otte figos">
            <h1 className='figod'>Play Zila 🤩</h1>
            </div>
            <hr />
      <div className="otwer1">

       <div className="topp">
       <div className="sss1">
        <h1 className='figo'>Good JOB! 🎉🎉</h1>
        <hr />
         <h1 className="toot">Good you must have come here after answering two questions.</h1>
          {/* Use the imported image */}
          <div className="dono">
          <img className='coco' src={conis} alt="Coin" />
          <p className='jakll'> Your 200 coins now ready!</p>
          </div>
         <button className='btn glow-on-hover'>
        <Link to="/Allquiz" className='hara'>Let's Start</Link>
      </button>

      <div className="down">
        <p className='inter_P'>Play more quizzes to test your knowledge and to continue view the all quizzz</p>
      </div>
       </div>
       </div>
       

        </div>

      <div className="intertext">
        <h2>Play Zila</h2>
        <p>Welcome to Play Zila, your ultimate destination for immersive play experiences! Dive into a world where excitement knows no bounds, where every corner holds a new adventure waiting to be explored. From thrilling games to captivating activities, Zila offers a plethora of entertainment options for individuals of all ages. Whether you're seeking adrenaline-pumping challenges or laid-back fun, our curated selection ensures there's something for everyone. Join us as we embark on a journey filled with laughter, competition, and unforgettable memories. Come, let your imagination run wild at Zila - where the joy of play knows no limits!</p>
        <div className="allinone">
          <p>All Point is Here!</p>
        <ul>
          <li>You can enjoy this whenever you want in this PLAY ZILA.</li>
          <li>Also this website is open for 24*7 days.</li>
          <li>Clicking on the start button here will show all the categories.</li>
        </ul>
        </div>
      </div>
    
    </div>
  );
};

export default LetStart;
