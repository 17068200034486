import React from 'react'

const Contact = () => {
  return (
    <div className='container011 color_about'> 
    <div className="otte figos">
    <h1 className="figod">Contact Us</h1>
    </div><hr /> 
    <div className="marall">
    <section>
            <h2>Play Zila - Contact Us</h2>
            <p>If you have any questions regarding this Privacy Policy, please contact us at contact Us.</p>
        </section>

        <section>Email : <a href="mailto:horserunnig@gmail.com">horserunnig@gmail.com</a></section>
         <section>Address :  second Floor, silver business point, utaran surat</section> 
         <p>We welcome any inquiries, feedback, or suggestions you may have. Please feel free to reach out to us via email or phone. Our team is here to assist you!
</p>

       <section><h2>Feedback:</h2>
       <p>We value your feedback and strive to improve our services continuously. Let us know about your experience with Play Zila by sending us an email or filling out our feedback form here.</p>
       </section>


       <section><h2>Privacy Policy:</h2>
       <p>Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</p>
       </section> 


       <section><h2>Accessibility:</h2>
       <p>We are committed to ensuring our website is accessible to all users, including those with disabilities. If you encounter any accessibility issues or require assistance, please contact us at <a href="mailto:horserunnig@gmail.com">horserunnig@gmail.com</a></p>
       </section> 

       <section><h2>Language Support:</h2>
       <p>Play Zila is support to English Language. So easily all visit this Play Zila website and enjoy this fun and increase the your knowledge.</p>
       </section> 
        </div>
    </div>
  )
}

export default Contact