// App.js
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "../src/components/Home";
import LetStart from './components/LetStart';
import AllQuiz from './components/AllQuiz';
import PlayQuiz from './components/PlayQuiz';
import QuizView from './components/QuizView';
import { ScoreData } from './components/ScoreData';
import About from './components/About';
import Policy from './components/Policy';
import Contact from './components/Contact';


const App = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/letstart" exact element={<LetStart />} />
          <Route path="/Allquiz" exact element={<AllQuiz />} />
          <Route path="/quiz/:id" element={<PlayQuiz />} />
          <Route path="/QuizView/:id" element={<QuizView />} />
          <Route path="/score/:score/:totalQuestions/:correctAnswers" element={<ScoreData />} />
          <Route path="/About" exact element={<About />} />
          <Route path="/Contact" exact element={<Contact />} />
          <Route path="/Policy" exact element={<Policy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
