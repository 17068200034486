import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuizData } from "../jsonData/QuizDatas";
import "../css/Home.css";

const Home = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate loading for 1 second
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Clear timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    const nextQuestionIndex = currentQuestionIndex + 1;
    if (nextQuestionIndex < QuizData.length) {
      setCurrentQuestionIndex(nextQuestionIndex);
    } else {
      navigate("/letstart");
    }
  };

  

  const currentQuestion = QuizData[currentQuestionIndex];

  if (isLoading) {
    return (
      <div className="container3">
        <div className="loader">
          <div className="spinner"></div>
          <h1>Loading... </h1>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="container3">
        <div className="fada">
          <div className="otwer">
            <h1 className='jaak'>Let's get start Quiz 😃</h1>
            <p className="lal">Answer 2 Question then start the Quiz.</p>
          </div>
        
          <div className='sss'>
            <h3 className='hada'>{currentQuestion.question}</h3>
            <ul className="yiy">
              {currentQuestion.options.map((option, optionIndex) => (
                <li className="iui" key={optionIndex} onClick={() => handleOptionSelect(option)}>
                  <input
                    type="radio"
                    id={`option-${optionIndex}`}
                    name="quiz-option"
                    value={option}
                    checked={selectedOption === option}
                    onChange={() => setSelectedOption(option)}
                  />
                  <label className='titl' htmlFor={`option-${optionIndex}`}>{option}</label>
                </li>
              ))}
            </ul>
          </div>
          
          <div className="inder">
            <h1 className='rahll'>Engage your brain and have fun with our Quiz..</h1>
            <p className='qwq'>This Play Zila is the Quiz give some best Question for your daily used.<br />
              This is Quiz is give to all new question for your used. You can view in lot
              of category in view the the 10 question and view the right score for your given answer.
            </p>
            <p className='qwq'><strong>Note:</strong> The coins shown in this play zila are not correct. Take special note of it. Also this is just for fun. Hence, play zila is not responsible in any way going forward.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
