import React from 'react'
import "../css/All.css"

const Policy = () => {
  return (
    <div className='container011 color_about'>
      <div className="otte figos">
        <h1 className="figod">Privacy Policy</h1>
        </div>
        <hr />
        <div className="marall"><section>
            <h2>Introduction</h2>
            <p>This page outlines our policies regarding the collection, use, and disclosure of Personal Information received from users of the site.</p>
            <p>At Play Zila, we prioritize the privacy concerns of our visitors, encompassing all information we collect and how we utilize it.</p>
        </section>

        <section>
            <h2>Consent</h2>
            <p>By using the Play Zila website, you agree to adhere to our Privacy Policy and its terms.</p>
        </section>

        <section>
            <h2>Information We Collect</h2>
            <p>Yes, we do collect information from you. Whenever prompted to provide personal information, the reasons will be clearly explained.</p>
            <p>We may obtain additional information from you when you reach out to us, including your name, email address, phone number, etc. Any content or attachments you choose to send us may also be collected.</p>
        </section>

        <main>
        <section>
            <h2>How We Use Your Information</h2>
            <p>We utilize the information gathered in various ways, such as:</p>
            <ul>
                <li>Providing, operating, and maintaining our website</li>
                <li>Enhancing, personalizing, and expanding our website</li>
                <li>Understanding and analyzing your website usage</li>
                <li>Developing new products, services, features, and functionality</li>
                <li>Communicating with you, directly or through partners, for customer service, updates, and marketing purposes</li>
                <li>Sending emails</li>
                <li>Detecting and preventing fraud</li>
            </ul>
        </section>

        <section>
            <h2>Log Files</h2>
            <p>Play Zila follows standard procedures by using log files, recording visitors' activities on the website. This includes internet protocol (IP) addresses, browser types, Internet Service Providers (ISPs), timestamps, referring/exit pages, and possibly the number of clicks. However, this information is not linked to personally identifiable data. It is used for analyzing trends, administering the site, tracking user movements, and gathering demographic information.</p>
        </section>

        <section>
            <h2>Cookies and Web Beacons</h2>
            <p>Like most websites, Play Zila uses cookies to store visitor preferences and track pages visited. This information helps customize webpage content based on browser type and other factors.</p>
        </section>

        <section>
            <h2>Google DoubleClick DART Cookie</h2>
            <p>Google, as a third-party vendor, uses DART cookies to serve ads to site visitors based on their visits to Play Zila and other sites on the internet. Visitors can opt out of DART cookies by visiting the <a href="https://policies.google.com/technologies/ads">Google ad and content network Privacy Policy</a>.</p>
        </section>

        <section>
            <h2>Our Advertising Partners</h2>
            <p>Some advertisers on our site may use cookies and web beacons. Each advertising partner has its own Privacy Policy.</p>
        </section>

        <section>
            <h2>Disclosure of Your Information</h2>
            <p>We do not sell, trade, or transfer your personal information to third parties. However, trusted third parties assisting us in website operation, business conduct, or customer service must keep this information confidential.</p>
        </section>

        <section>
            <h2>Third-Party Links</h2>
            <p>Play Zila may contain links to third-party websites, each with its own separate privacy policies. We are not responsible for the content or activities of these linked sites.</p>
        </section>

        <section>
            <h2>CCPA Privacy Rights & GDPR Data Protection Rights</h2>
            <p>We respect your privacy rights under CCPA and GDPR. You have the right to request access, correction, deletion, or restriction of your personal data. If you wish to exercise these rights, please contact us.</p>
        </section>

        <section>
            <h2>Children's Information</h2>
            <p>We prioritize children's online protection and encourage parental supervision. Play Zila does not knowingly collect personal information from children under 13. If such information is inadvertently collected, please contact us for its prompt removal.</p>
        </section>

        <section>
            <h2>Security</h2>
            <p>While we endeavor to safeguard your Personal Information, no method of transmission over the Internet is entirely secure. We cannot guarantee absolute data security.</p>
        </section>

        <section>
            <h2>Contact Us</h2>
            <p>If you have any questions regarding this Privacy Policy, please contact us at <a href="mailto:horserunnig@gmail.com">horserunnig@gmail.com</a>.</p>
        </section>

        <section>
            <h2>Consent</h2>
            <p>By using our website, you consent to our Privacy Policy and agree to its terms.</p>
        </section>
    </main>

    <footer>
        <p>Note: This Privacy Policy page was created by Play Zila. For inquiries, please contact us.</p>
    </footer></div>
        

    </div>
  )
}

export default Policy