import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { QuizD } from "../jsonData/QuizDa"
import "../css/PlayQuiz.css"


const PlayQuiz = () => {
  // Get the ID from the URL parameter
  const { id } = useParams(); 

  return (
    <div className='container3'>
      <div className="otte figos">
            <h1 className='figod'>Play Zila 🤩</h1>
            </div>
            <hr />
    <div className="otwer1">
    <div className="topp">
     <div className="sss1">
      <h1 className='figo'>Now Start Quizzz  😉</h1>
      <hr />
       <h1 className="toot">Good you must have select to one Quizzz.</h1>
        {/* Use the imported image */}
        
       <button className='btn glow-on-hover'>
      <Link to={`/QuizView/${id}`} className='hara'>Let's Play</Link>
    </button>

    <div className="down">
      <p>Play more quizzes to test your knowledge and to continue view the all quizzz</p>
    </div>
     </div>
     </div>
    
    <div className="intertext">
    <h2>Quizz :</h2>
    <p>Here you have crossed another step and come forward.  Thank you for them.  Here you have clicked on any one of the categories shown above.  After that you have reached here.  Now if you click on let's play then a question and its four options will be shown in front of you.  So you can see his true jawan here.</p>

    </div>
    <div className="allinone intertext">
          <h2>All Point is Here!</h2>
        <ul>
          <li>You can enjoy this whenever you want in this PLAY ZILA.</li>
          <li>Also this website is open for 24*7 days.</li>
          <li>Clicking on the start button here will show all the Questions.</li>
        </ul>
        </div>

      </div>

  
  </div>
  );
};

export default PlayQuiz;
