import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import "../css/Score.css"

const ScoreData = () => {
  const navigate = useNavigate();
  const { score, totalQuestions, correctAnswers } = useParams();

  const goToHomePage = () => {
    console.log("")
    navigate('/Allquiz'); // Make sure '/' is the correct path for your home page
  };

  return(
    <>
    <div className="containert">
    <div className="otte figos">
            <h1 className='figod'>Play Zila 🤩</h1>
            </div>
            <hr />
    <div className="otwer1">
    <div className="topp">
    <div className="sss1 text-center">
    <h2 className='figoaa'>The Quizzz congratulations 😀 </h2>
    <hr />
   <h2 className="figo">You completed the quizzz!</h2>
        <p className="rere">Total Questions:<b> {totalQuestions} </b></p>
        <p className="rere">Correct Answers:<b> {correctAnswers} </b></p>
        <p className="rere1">Score: <b> {score} / {totalQuestions} </b></p>
        <button id="homeButton" className='btn glow-on-hover' onClick={() => goToHomePage()}> All Quiz </button>
        </div>
       </div>

       <div className="intertext">
    <h2>Quizz :</h2>
    <p className="raak">Congratulations you have played the quiz.  In which there were so many {totalQuestions}.  In which you have answered so many {correctAnswers} from this  {totalQuestions} question.  Which shows you above and your scroe is {score} / {totalQuestions}.  To play again you have to click on all Quiz button so you will see all categories again.</p>

    </div>
    <div className="allinone intertext">
          <h2>All Point is Here!</h2>
        <ul>
          <li>You can enjoy this whenever you want in this PLAY ZILA.</li>
          <li>Also this website is open for 24*7 days.</li>
          <li>Clicking on the start button here will show all the Questions.</li>
        </ul>
        </div>

        </div>
        </div>
    </>
  )

}

export { ScoreData };