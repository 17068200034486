export const QuizD = [
    {
      "id": 1,
      "name": "General Knowledge",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i1.png",
      
      "questions": [
        { "id" : 1,
          "question": "What is the capital of Australia?",
          "options": ["Sydney", "Canberra", "Melbourne", "Brisbane"],
          "answer": 1
        },
        {
          "id" : 2,
          "question": "Who is known as the father of modern physics?",
          "options": ["Isaac Newton", "Albert Einstein", "Galileo Galilei", "Nikola Tesla"],
          "answer": 1
        },
        {
          "id" : 3,
          "question": "What is the chemical symbol for gold?",
          "options": ["Au", "Ag", "Fe", "Pb"],
          "answer": 0
        },
        {
          "id" : 4,
          "question": "Which country is known as the Land of the Rising Sun?",
          "options": ["China", "South Korea", "Japan", "Thailand"],
          "answer": 2
        },
        {
          "id" : 5,
          "question": "Who wrote the famous novel To Kill a Mockingbird?",
          "options": ["Ernest Hemingway", "Harper Lee", "Mark Twain", "J.D. Salinger"],
          "answer": 1
        },
        {
          "id" : 6,
          "question": "What is the tallest mountain in the world?",
          "options": ["Mount Kilimanjaro", "Mount Everest", "Mount Fuji", "K2"],
          "answer": 1
        },
        { "id" : 7,
        "question": "Which gas makes up the majority of the Earth's atmosphere?",
        "options": ["Oxygen", "Nitrogen", "Carbon dioxide", "Hydrogen"],
        "answer": 1
      },
      {
        "id" : 8,
        "question": "What is the capital of Canada?",
        "options": ["Toronto", "Vancouver", "Ottawa", "Montreal"],
        "answer": 2
      },
      {
        "id" : 9,
        "question": "Who is the author of the Harry Potter book series?",
        "options": ["J.R.R. Tolkien", "J.K. Rowling", "C.S. Lewis", "George R.R. Martin"],
        "answer": 1
      },
      {
        "id" : 10,
        "question": "What is the largest organ in the human body?",
        "options": ["Liver", "Brain", "Skin", "Heart"],
        "answer": 2
      }
      ]
    },
    {
      "id": 2,
      "name": "Business",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i2.png",
      "questions": [
        {
          "id" : 1,
          "question": "Who is the current CEO of Tesla?",
          "options": ["Jeff Bezos", "Elon Musk", "Tim Cook", "Sundar Pichai"],
          "answer": 1
        },
        {
          "id" : 2,
          "question": "What is the currency of Japan?",
          "options": ["Yen", "Euro", "Dollar", "Pound"],
          "answer": 0
        },
        {
          "id" : 3,
          "question": " Which social media platform is known for its disappearing photos and videos feature?",
          "options": ["Facebook", "Instagram", "Snapchat", "Twitter"],
          "answer": 2
        },
        {
          "id" : 4,
          "question": "Who is the founder of Microsoft Corporation?",
          "options": ["Steve Jobs", "Bill Gates", "Jeff Bezos", "Mark Zuckerberg"],
          "answer": 1
        },
        {
          "id" : 5,
          "question": "What is the largest e-commerce company in the world by revenue?",
          "options": ["Alibaba Group", "Amazon", "eBay", "Walmart"],
          "answer": 1
        },
        {
          "id" : 6,
          "question": "Which company is the world's largest manufacturer of smartphones?",
          "options": ["Samsung", "Apple", "Huawei", "Xiaomi"],
          "answer": 0
        },
        {
          "id" : 7,
          "question": "What does the acronym CEO stand for in the business world?",
          "options": ["Chief Executive Officer", "Chief Financial Officer", "Chief Operating Officer", "Chief Marketing Officer"],
          "answer": 0
        },
        {
          "id" : 8,
          "question": "Which social media platform is primarily used for professional networking?",
          "options": ["Facebook", "Instagram", "LinkedIn", "Twitter"],
          "answer": 2
        },
        {
          "id" : 9,
          "question": "What is the world's largest coffeehouse chain by the number of locations?",
          "options": ["Dunkin' Donuts", "Starbucks", "Costa Coffee", "Tim Hortons"],
          "answer": 1
        },
        {
          "id" : 10,
          "question": "What is the full form of IPO in the business context?",
          "options": ["Initial Public Offering", "International Patent Organization", "International Production Order", "Initial Private Offering"],
          "answer": 0
        }
      ]
    },
    {
      "id": 3,
      "name": "Science",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i3.png",
      "questions": [
        {
          "id" : 1,
          "question": "Which of the following is a large blood vessel that carries blood away from the heart?",
          "options": ["Vein", "Artery", "Capillary", "Nerve"],
          "answer": 1
        },
        {
          "id" : 2,
          "question": "Which of the following is not a member of the vitamin B complex?",
          "options": ["Thiamine", "Riboflavin", "Folic acid", "Ascorbic acid"],
          "answer": 3
        },
        {
          "id" : 3,
          "question": "Fungi are plants that lack........",
          "options": ["Oxygen", "Carbon dioxide", "Chlorophyll", "None of these"],
          "answer": 2
        },
        {
          "id" : 4,
          "question": "What makes a reptile a reptile?",
          "options": ["Cold blooded", "Warm Blooded", "Non-Hearing", "Egg-laying"],
          "answer": 3
        },
        {
          "id" : 5,
          "question": "Which blood vessels have the smallest diameter?",
          "options": ["Capillaries", "Arterioles", "Venules", "Lymphatic"],
          "answer": 0
        },
        {
          "id" : 6,
          "question": "Which of the following is an air-borne disease?",
          "options": ["Measles", "Typhoid", "Pink eye", "None of the above"],
          "answer": 1
        },
        {
          "id" : 7,
          "question": "A yellow dust appears on the fingers, whenever we touch the middle of a flower. These tiny yellow grains are one of the most precious substances in nature because they contain the secret of plant life.  What is this dust called?",
          "options": ["Pollen", "Sperm", "Spore", "Sporocyst"],
          "answer": 0
        },
        {
          "id" : 8,
          "question": "Which organ of the body produces the fluid known as bile?",
          "options": ["Liver", "Pancreas", "Gall bladder", "Kidney"],
          "answer": 0
        },
        {
          "id" : 9,
          "question": "Which of the following hormones is a steroid?",
          "options": ["Estrogen", "Glucagon", "Insulin", "Oxytocin"],
          "answer": 0
        },
        {
          "id" : 10,
          "question": "Which one of the following is not a function of the liver?",
          "options": ["Regulation of blood sugar", "Enzyme activation", "Detoxification", "Reproduction"],
          "answer": 3
        }
      ]
    },
    {
      "id": 4,
      "name": "Bollywood",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i4.png",
      "questions": [
        {
          "id" : 1,
          "question": "Who is known as the “King of Bollywood”?",
          "options": ["Shah Rukh Khan", "Amitabh Bachchan", "Salman Khan", "Aamir Khan"],
          "answer": 0
        },
        {
          "id" : 2,
          "question": "Which actor made his debut with the film “Biwi Ho To Aisi” but rose to fame with the film “Maine Pyar Kiya”?",
          "options": ["Salman Khan", "Shah Rukh Khan", "Aamir Khan", "Sanjay Dutt"],
          "answer": 0
        },
        {
          "id" : 3,
          "question": "Who played the role of “Mogambo” in the movie “Mr. India”?",
          "options": ["Amrish Puri", "Om Puri", "Paresh Rawal", "Naseeruddin Shah"],
          "answer": 0
        },
        {
          "id" : 4,
          "question": "Which Bollywood actor has won the maximum number of Filmfare Awards for Best Actor?",
          "options": ["Amitabh Bachchan", "Shah Rukh Khan", "Aamir Khan", "Dilip Kumar"],
          "answer": 1
        },
        {
          "id" : 5,
          "question": "Who directed the film “Lagaan”?",
          "options": ["Sanjay Leela Bhansali", "Karan Johar", "Ashutosh Gowariker", "Farhan Akhtar"],
          "answer": 2
        },
        {
          "id" : 6,
          "question": "Which film won the National Film Award for Best Hindi Film in 2019?",
          "options": ["Andhadhun", "Badhaai Ho", "Uri: The Surgical Strike", "Gully Boy"],
          "answer": 3
        },
        {
          "id" : 7,
          "question": "Which actor played the role of “Kabir Khan” in the movie “Chak De India”?",
          "options": ["Shah Rukh Khan", "Aamir Khan", "Salman Khan", "Ranbir Kapoor"],
          "answer": 0
        },
        {
          "id" : 8,
          "question": "Who sang the song “Chaiyya Chaiyya” from the movie “Dil Se”?",
          "options": ["A.R. Rahman", "Sukhwinder Singh", "Sonu Nigam", "Udit Narayan"],
          "answer": 1
        },
        {
          "id" : 9,
          "question": "Which was the first Indian film to be nominated for an Academy Award for Best Foreign Language Film?",
          "options": ["Mother India", "Salaam Bombay!", "Lagaan", "Taare Zameen Par"],
          "answer": 0
        },
        {
          "id" : 10,
          "question": "Who played the role of “Munna Bhai” in the film “Munna Bhai M.B.B.S.”?",
          "options": ["Sanjay Dutt", "Salman Khan", "Aamir Khan", "Shah Rukh Khan"],
          "answer": 0
        }
      ]
    },
    {
      "id": 5,
      "name": "Food",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i5.png",
      "questions": [
        {
          "id" : 1,
          "question": "Which fruit resembles 60% of human DNA?",
          "options": ["Strawberry", "Bananas", "Apples", "Orange"],
          "answer": 1
        },
        {
          "id" : 2,
          "question": "Which country did the french fries originate from?",
          "options": ["France", "USA", "UK", "Belgium"],
          "answer": 3
        },
        {
          "id" : 3,
          "question": "Which food never rots and doesn’t require preservatives to keep fresh?",
          "options": ["Tea", "Honey", "Peanuts", "Oats"],
          "answer": 1
        },
        {
          "id" : 4,
          "question": "Which food contains the most calories per gram?",
          "options": ["Chocolate", "Chia seeds", "Avocado", "Pistachio"],
          "answer": 2
        },
        {
          "id" : 5,
          "question": "Which was the first fast-food restaurant to open an outlet in China?",
          "options": ["Jollibee", "Subway", "KFC", "Mc Donald's"],
          "answer": 2
        },
        {
          "id" : 6,
          "question": "Among the numerous pizza toppings, which is the most commonly used?",
          "options": ["Extra cheese", "Mushrooms", "Pepperoni", "Pineapple"],
          "answer": 2
        },
        {
          "id" : 7,
          "question": "Where was Chicken Tikka masala invented?",
          "options": ["London, England", "New Delhi, India", "Glasgow, Scotland", "Punjab, India"],
          "answer": 2
        },
        {
          "id" : 8,
          "question": "Which traditional Goan sweet can have up to 16 layers?",
          "options": ["Cocada", "Bebinca", "Dodol", "Serradura"],
          "answer": 1
        },
        {
          "id" : 9,
          "question": "What is the staple food of almost one-third of the world's population?",
          "options": ["Wheat", "Bread", "Rice", "Lentils"],
          "answer": 2
        },
        {
          "id" : 10,
          "question": "‍How many pairs of chopsticks does China consume in a year?",
          "options": ["20 billion", "1 billion", "45 billion", "100 million"],
          "answer": 2
        }
      ]
    },
    {
      "id": 6,
      "name": "Bank",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i6.png",
      "questions": [
        {
          "id" : 1,
          "question": "We should keep our savings with banks because",
          "options": ["It is safe", "Earns interest", "Can be withdrawn anytime", "All of above"],
          "answer": 3
        },
        {
          "id" : 2,
          "question": "Bank does not give loan against",
          "options": ["Gold Ornaments", "LIC policy", "Lottery ticket", "NSC"],
          "answer": 2
        },
        {
          "id" : 3,
          "question": "Bank having maximum number of branches in India",
          "options": ["Reserve Bank of India", "State Bank of India", "Punjab National Bank", "Bank of Baroda"],
          "answer": 1
        },
        {
          "id" : 4,
          "question": "100/- Rupee note is signed by",
          "options": ["Prime Minister", "Finance Minister", "RBI Governor", "None of above"],
          "answer": 2
        },
        {
          "id" : 5,
          "question": "ATM password should be kept in",
          "options": ["Personal diary", "Office diary", "Memory", "All of above"],
          "answer": 2
        },
        {
          "id" : 6,
          "question": "ATM password to be shared only with",
          "options": ["Spouse", "Obedient son", "Obedient daughter", "PineaNone of abovepple"],
          "answer": 3
        },
        {
          "id" : 7,
          "question": "Nomination can be done in",
          "options": ["Savings Bank account", "Recurring Deposit account", "Fixed Deposit account", "All of above"],
          "answer": 3
        },
        {
          "id" : 8,
          "question": "Who is the present Governor of RBI?",
          "options": ["K.C. Chakrabarty", "D K Mittal", "Raghuram Rajan", "Montek Singh Ahluwalia"],
          "answer": 2
        },
        {
          "id" : 9,
          "question": "Minimum age required to open SB account in the bank",
          "options": ["8 years", "10 years", "12 years", "None of above"],
          "answer": 1
        },
        {
          "id" : 10,
          "question": "‍Bank does not provide loans for",
          "options": ["Crop loans", "Education loans", "Home loans", "Drinking & Gambling"],
          "answer": 3
        }
      ]
    },
    {
      "id": 7,
      "name": "Tech",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i7.png",
      "questions": [
        {
          "id" : 1,
          "question": "In which decade was the American Institute of Electrical Engineers (AIEE) founded?",
          "options": ["1850s", "1880s", "1930s", "1950s"],
          "answer": 1
        },
        {
          "id" : 2,
          "question": "What is part of a database that holds only one type of information?",
          "options": ["Report", "Field", "Record", "File"],
          "answer": 1
        },
        {
          "id" : 3,
          "question": "'OS' computer abbreviation usually means ?",
          "options": ["Order of Significance", "Open Software", "Operating System", "Optical Sensor"],
          "answer": 2
        },
        {
          "id" : 4,
          "question": "In which decade with the first transatlantic radio broadcast occur?",
          "options": ["1850s", "1860s", "1870s", "1900s"],
          "answer": 3
        },
        {
          "id" : 5,
          "question": "'.MOV' extension refers usually to what kind of file?",
          "options": ["Image file", "Animation/movie file", "Audio file", "MS Office document"],
          "answer": 1
        },
        {
          "id" : 6,
          "question": "In which decade was the SPICE simulator introduced?",
          "options": ["1950s", "1960s", "1970s", "1980s"],
          "answer": 2
        },
        {
          "id" : 7,
          "question": "Most modern TV's draw power even if turned off. The circuit the power is used in does what function?",
          "options": ["Sound", "Remote control", "Color balance", "High voltage"],
          "answer": 1
        },
        {
          "id" : 8,
          "question": "Which is a type of Electrically-Erasable Programmable Read-Only Memory?",
          "options": ["Flash", "Flange", "Fury", "FRAM"],
          "answer": 0
        },
        {
          "id" : 9,
          "question": "The purpose of choke in tube light is ?",
          "options": ["To decrease the current", "To increase the current", "To decrease the voltage momentarily", "To increase the voltage momentarily"],
          "answer": 3
        },
        {
          "id" : 10,
          "question": "‍'.MPG' extension refers usually to what kind of file?",
          "options": ["WordPerfect Document file", "MS Office document", "Animation/movie file", "Image file"],
          "answer": 2
        }
      ]
    },
    {
      "id": 8,
      "name": "Sports",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i8.png",
      "questions": [
        {
          "id" : 1,
          "question": "Who has been appointed as the permanent CEO of the International Cricket Council (ICC)?",
          "options": ["Geoff Allardyce", "John Felix", "Mark Harris", "David Lizard"],
          "answer": 0
        },
        {
          "id" : 2,
          "question": "Who has been selected by the Badminton World Federation (BWF) for the Lifetime Achievement Award this year?",
          "options": ["Saina Nehwal", "Carolina Marine", "Prakash Padukone", "Prakash Padukone"],
          "answer": 2
        },
        {
          "id" : 3,
          "question": "Who became the youngest ATP 500 champion?",
          "options": ["John Newcomb", "Rod Laver", "Jimmy Connors", "Carlos Alcarazu"],
          "answer": 3
        },
        {
          "id" : 4,
          "question": "Ranji Trophy is related to the?",
          "options": ["Hockey", "Cricket", "Football", "None of the above"],
          "answer": 1
        },
        {
          "id" : 5,
          "question": "Who has been appointed as the CEO of the International Cricket Council?",
          "options": ["Manu Sawhney", "Geoff Allardyce", "Imran Khawaja", "Greg Barkley"],
          "answer": 0
        },
        {
          "id" : 6,
          "question": "Veteran author, football journalist, and sports commentator ___________ has passed away due to health complications.",
          "options": ["Vikram Kapadia", "Rohan Kapadia", "Novi Kapadia", "Deepak Kapadia"],
          "answer": 2
        },
        {
          "id" : 7,
          "question": "Who among the following has won the Vienna Tennis Open or Erst Bank Open 2021?",
          "options": ["Roger Federer", "Rafael Nadal", "Novak Djokovic", "Alexander Zverev"],
          "answer": 3
        },
        {
          "id" : 8,
          "question": "Who among the following has become the first Indian player to play in the Australian Big Bash League?",
          "options": ["Rajat Bhatia", "Praveen Tambe", "Unmukt Chand", "Ajit Chandila"],
          "answer": 2
        },
        {
          "id" : 9,
          "question": "Who among the following has been ranked among the top 10 highest-paid women players of Forbes in the year 2021?",
          "options": ["Venus Williams", "Simone Biles", "PV Sindhu", "Naomi Osaka"],
          "answer": 3
        },
        {
          "id" : 10,
          "question": "‍Major Rajyavardhan Singh Rathore won which medal in shooting in the 28th Olympic Games?",
          "options": ["Gold", "Silver", "Bronze", "Consolation medal"],
          "answer": 1
        }
      ]
    },
    {
      "id": 9,
      "name": "Cricket",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i9.png",
      "questions": [
        {
          "id" : 1,
          "question": "Which Australian cricketer has taken the most wickets in World Cup history?",
          "options": ["Glenn McGrath", "Shane Warne", "Brett Lee", "Mitchell Starc"],
          "answer": 3
        },
        {
          "id" : 2,
          "question": "Which Indian cricketer has captained the Indian team in the most World Cup matches?",
          "options": ["Sourav Ganguly", "MS Dhoni", "Virat Kohli", "Rohit Sharma"],
          "answer": 1
        },
        {
          "id" : 3,
          "question": "Which Australian cricketer has been named the Man of the Match in the most World Cup matches?",
          "options": ["Ricky Ponting", "Adam Gilchrist", "Michael Hussey", "David Warner"],
          "answer": 0
        },
        {
          "id" : 4,
          "question": "Which Indian team has won the most World Cups?",
          "options": ["India", "Australia", "West Indies", "Pakistan"],
          "answer": 1
        },
        {
          "id" : 5,
          "question": "In which year did Australia first win the World Cup?",
          "options": ["1987", "1999", "2003", "1975"],
          "answer": 0
        },
        {
          "id" : 6,
          "question": "Which Indian cricketer has scored the most centuries in World Cup history?",
          "options": ["Sachin Tendulkar", "Virat Kohli", "Rohit Sharma", "MS Dhoni"],
          "answer": 2
        },
        {
          "id" : 7,
          "question": "Which Australian cricketer has taken the most hat-tricks in World Cup history?",
          "options": ["Glenn McGrath", "Shane Warne", "Brett Lee", "Mitchell Starc"],
          "answer": 0
        },
        {
          "id" : 8,
          "question": "Which Australian captain has led Australia to the most World Cup victories?",
          "options": ["Ricky Ponting", "Adam Gilchrist", "Michael Hussey", "David Warner"],
          "answer": 0
        },
        {
          "id" : 9,
          "question": "Which Indian player was named the Man of the Tournament in the 2011 World Cup?",
          "options": ["MS Dhoni", "Virat Kohli", "Rohit Sharma", "Sachin Tendulkar"],
          "answer": 0
        },
        {
          "id" : 10,
          "question": "‍Which Australian player was named the Man of the Tournament in the 2015 World Cup?",
          "options": ["Michael Clarke", "Glenn Maxwell", "Mitchell Johnson", "Chris Gayle"],
          "answer": 1
        }
      ]
    },
    {
      "id": 10,
      "name": "Geography",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i10.png",
      "questions": [
        {
          "id" : 1,
          "question": "In which city of India, 90% of world’s small diamonds processed?",
          "options": ["Baroda", "Mumbai", "Surat", "Jaipur"],
          "answer": 2
        },
        {
          "id" : 2,
          "question": "Paradip Port is located on the delta of which among the following? ",
          "options": ["Rihand", "Mahanadi", "Baitarni", "None of the above"],
          "answer": 1
        },
        {
          "id": 3,
          "question": "Which among the following is India’s first Expressway?",
          "options": ["Mumbai-Pune Expressway", "Ahmedabad-Vadodara Expressway", "Delhi-Gurgaon Expressway", "Jaipur-Kishangarh Expressway"],
          "answer": 0
        },
        {
          "id": 4,
          "question": "Indravati Hydro Electric Project belongs to which state?",
          "options": ["Tamil Nadu", "Orissa", "Karnataka", "Andhra Pradesh"],
          "answer": 1
        },
        {
          "id": 5,
          "question": "“Salboni ” in west Bengal is best known for which of the following?",
          "options": ["Indian Security Press", "Coin Mint", "Modernized Currency Notes Press", "None of the above"],
          "answer": 2
        },
        {
          "id": 6,
          "question": "Which among the following is not correctly matched?",
          "options": ["Firozabad (UP) – Glass Bangles", "Bhagalpur (Bihar) – Silk", "Jalandhra (Punjab ) – Sports Material", "Sambhar (Rajasthan) – hand block printing"],
          "answer": 3
        },
        {
          "id": 7,
          "question": "Which among the following tribe has the largest population in India?",
          "options": ["Bhils", "Meenas", "Gonds", "Sahariyas"],
          "answer": 0
        },
        {
          "id": 8,
          "question": "“Mullaperiyar Dam” has been a long pending issue between which of the following two states?",
          "options": ["Karnataka & Kerala", "Andhra Pradesh and Tamil Nadu", "Karnataka & Tamil Nadu", "Tamil Nadu and Kerala"],
          "answer": 3
        },
        {
          "id": 9,
          "question": "Maximum Jute mills are located in which among the following states?",
          "options": ["West Bengal", "Assam", "Andhra Pradesh", "Uttar Pradesh"],
          "answer": 0
        },
        {
          "id": 10,
          "question": "The Western Ghats region runs to a length of 1600 kilometers. Which among the following states are covered by the Western Ghats?",
          "options": ["Tamil Nadu, Karnataka, Kerala", "Tamil Nadu, Karnataka, Kerala, Goa", "Tamil Nadu, Karnataka, Kerala, Goa, Maharashtra", "Tamil Nadu, Karnataka, Kerala, Goa, Maharashtra , Gujarat"],
          "answer": 3
        }
      ]
    },
    {
      "id": 11,
      "name": "Animals",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i11.png",
      "questions": [
        {
          "id": 1,
          "question": "What is the largest mammal?",
          "options": ["Elephant", "Blue Whale", "Giraffe", "Hippopotamus"],
          "answer": 1
        },
        {
          "id": 2,
          "question": "Which animal is known as the 'king of the jungle'?",
          "options": ["Tiger", "Lion", "Leopard", "Cheetah"],
          "answer": 1
        },
        {
          "id": 3,
          "question": "What is the only mammal capable of flight?",
          "options": ["Bat", "Squirrel", "Flying Fox", "Pterosaur"],
          "answer": 0
        },
        {
          "id": 4,
          "question": "What is the largest living reptile?",
          "options": ["Anaconda", "Nile Crocodile", "Komodo Dragon", "Green Sea Turtle"],
          "answer": 1
        },
        {
          "id": 5,
          "question": "Which bird is known for its ability to mimic human speech?",
          "options": ["Crow", "Parrot", "Pigeon", "Owl"],
          "answer": 1
        },
        {
          "id": 6,
          "question": "Which animal is considered the fastest land mammal?",
          "options": ["Gazelle", "Cheetah", "Horse", "Pronghorn"],
          "answer": 1
        },
        {
          "id": 7,
          "question": "What is the national animal of China?",
          "options": ["Panda", "Tiger", "Red Panda", "Giant Panda"],
          "answer": 3
        },
        {
          "id": 8,
          "question": "Which of these animals is not a marsupial?",
          "options": ["Kangaroo", "Koala", "Wombat", "Armadillo"],
          "answer": 3
        },
        {
          "id": 9,
          "question": "What is the only continent where you won't find any native reptiles or amphibians?",
          "options": ["Antarctica", "Africa", "South America", "Europe"],
          "answer": 0
        },
        {
          "id": 10,
          "question": "Which of these animals is known for its ability to change color to match its surroundings?",
          "options": ["Chameleon", "Iguana", "Gecko", "Lizard"],
          "answer": 0
        }
      ]
    },
    {
      "id": 12,
      "name": "History",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i12.png",
      "questions": [
        {
          "id": 1,
          "question": "Who was the first President of the United States?",
          "options": ["Thomas Jefferson", "George Washington", "Abraham Lincoln", "John Adams"],
          "answer": 1
        },
        {
          "id": 2,
          "question": "In which year did World War II end?",
          "options": ["1943", "1945", "1947", "1950"],
          "answer": 1
        },
        {
          "id": 3,
          "question": "Who wrote 'The Communist Manifesto'?",
          "options": ["Vladimir Lenin", "Joseph Stalin", "Karl Marx", "Leon Trotsky"],
          "answer": 2
        },
        {
          "id": 4,
          "question": "Which ancient civilization built the Great Pyramid of Giza?",
          "options": ["Mesopotamia", "Greece", "Egypt", "Rome"],
          "answer": 2
        },
        {
          "id": 5,
          "question": "Who was the first female Prime Minister of the United Kingdom?",
          "options": ["Theresa May", "Margaret Thatcher", "Angela Merkel", "Indira Gandhi"],
          "answer": 1
        },
        {
          "id": 6,
          "question": "Who was the leader of the Soviet Union during the Cuban Missile Crisis?",
          "options": ["Joseph Stalin", "Leonid Brezhnev", "Nikita Khrushchev", "Mikhail Gorbachev"],
          "answer": 2
        },
        {
          "id": 7,
          "question": "What year did Christopher Columbus first reach the Americas?",
          "options": ["1492", "1519", "1607", "1776"],
          "answer": 0
        },
        {
          "id": 8,
          "question": "Who was the longest-reigning monarch in British history?",
          "options": ["Queen Elizabeth I", "Queen Victoria", "King Henry VIII", "King George III"],
          "answer": 1
        },
        {
          "id": 9,
          "question": "Which treaty formally ended World War I?",
          "options": ["Treaty of Versailles", "Treaty of Brest-Litovsk", "Treaty of Trianon", "Treaty of Portsmouth"],
          "answer": 0
        },
        {
          "id": 10,
          "question": "Who was the first emperor of Rome?",
          "options": ["Julius Caesar", "Augustus", "Nero", "Caligula"],
          "answer": 1
        }
      ]
    },
    {
      "id": 13,
      "name": "News",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i13.png",
      "questions": [
        {
          "id": 1,
          "question": "Which country recently launched the world's largest floating solar power plant?",
          "options": ["China", "United States", "India", "Germany"],
          "answer": 0
        },
        {
          "id": 2,
          "question": "Which technology company recently became the first to reach a market capitalization of $3 trillion?",
          "options": ["Apple", "Amazon", "Microsoft", "Google"],
          "answer": 0
        },
        {
          "id": 3,
          "question": "Which country recently announced plans to ban the sale of new gasoline and diesel cars by 2035?",
          "options": ["France", "Germany", "United Kingdom", "Norway"],
          "answer": 2
        },
        {
          "id": 4,
          "question": "Which city was recently announced as the host of the 2032 Summer Olympics?",
          "options": ["Tokyo", "Paris", "Los Angeles", "Brisbane"],
          "answer": 3
        },
        {
          "id": 5,
          "question": "Which company recently surpassed Facebook to become the world's most valuable social media company?",
          "options": ["Meta Platforms (Facebook)", "Tencent", "Twitter", "Snap Inc."],
          "answer": 1
        },
        {
          "id": 6,
          "question": "Which cryptocurrency recently hit an all-time high value, surpassing $70,000 per coin?",
          "options": ["Bitcoin", "Ethereum", "Dogecoin", "Ripple (XRP)"],
          "answer": 0
        },
        {
          "id": 7,
          "question": "Which space agency recently announced plans to return humans to the Moon by 2025?",
          "options": ["NASA", "ESA", "Roscosmos", "CNSA"],
          "answer": 0
        },
        {
          "id": 8,
          "question": "Which country recently experienced a significant increase in COVID-19 cases due to the Omicron variant?",
          "options": ["United States", "Australia", "Brazil", "South Africa"],
          "answer": 3
        },
        {
          "id": 9,
          "question": "Which environmental activist was recently awarded the Nobel Peace Prize?",
          "options": ["Greta Thunberg", "David Attenborough", "Jane Goodall", "Al Gore"],
          "answer": 0
        },
        {
          "id": 10,
          "question": "Which global event recently led to widespread supply chain disruptions and shortages?",
          "options": ["COVID-19 pandemic", "Cyberattack on a major company", "Trade war between two countries", "Natural disaster"],
          "answer": 0
        }
      ]
    },
    {
      "id": 14,
      "name": "Politics",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i14.png",
      "questions": [
        {
          "id": 1,
          "question": "Who is the current President of the United States?",
          "options": ["Donald Trump", "Joe Biden", "Barack Obama", "Hillary Clinton"],
          "answer": 1
        },
        {
          "id": 2,
          "question": "Which country recently held a general election resulting in a change of government?",
          "options": ["Germany", "France", "United Kingdom", "Canada"],
          "answer": 0
        },
        {
          "id": 3,
          "question": "Which political leader recently faced impeachment proceedings for the second time?",
          "options": ["Vladimir Putin", "Angela Merkel", "Emmanuel Macron", "Donald Trump"],
          "answer": 3
        },
        {
          "id": 4,
          "question": "Which nation recently held a highly contentious presidential election, leading to protests and violence?",
          "options": ["Russia", "Brazil", "Ukraine", "Belarus"],
          "answer": 3
        },
        {
          "id": 5,
          "question": "Which political figure recently announced their candidacy for the upcoming presidential election in their country?",
          "options": ["Justin Trudeau", "Xi Jinping", "Narendra Modi", "Kamala Harris"],
          "answer": 0
        },
        {
          "id": 6,
          "question": "Which country recently faced significant political unrest due to protests against government corruption and economic instability?",
          "options": ["Venezuela", "Argentina", "South Africa", "Lebanon"],
          "answer": 3
        },
        {
          "id": 7,
          "question": "Which world leader recently faced backlash for their handling of a refugee crisis at the border of their country?",
          "options": ["Angela Merkel", "Vladimir Putin", "Joe Biden", "Boris Johnson"],
          "answer": 2
        },
        {
          "id": 8,
          "question": "Which political party recently won a majority in the parliamentary elections of a European Union member state?",
          "options": ["Labour Party (UK)", "Conservative Party (UK)", "Liberal Party (Canada)", "Rassemblement National (France)"],
          "answer": 0
        },
        {
          "id": 9,
          "question": "Which country recently held a referendum resulting in a decision to amend their constitution?",
          "options": ["Switzerland", "Australia", "Japan", "South Korea"],
          "answer": 0
        },
        {
          "id": 10,
          "question": "Which political leader recently faced criticism for their handling of a major environmental disaster in their country?",
          "options": ["Scott Morrison", "Xi Jinping", "Emmanuel Macron", "Jair Bolsonaro"],
          "answer": 3
        }
      ]
    },
    {
      "id": 15,
      "name": "Art",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i15.png",
      "questions": [
        {
          "id": 1,
          "question": "Who is the artist behind the controversial artwork titled 'Comedian' featuring a banana duct-taped to a wall?",
          "options": ["Banksy", "Damien Hirst", "Maurizio Cattelan", "Jeff Koons"],
          "answer": 2
        },
        {
          "id": 2,
          "question": "Which museum recently announced the acquisition of a rare painting by Vincent van Gogh?",
          "options": ["Louvre Museum (France)", "The Metropolitan Museum of Art (USA)", "Rijksmuseum (Netherlands)", "Tate Modern (UK)"],
          "answer": 1
        },
        {
          "id": 3,
          "question": "Who won the Turner Prize in 2021?",
          "options": ["Tracey Emin", "Helen Marten", "Tai Shani", "Collective (Scotland)"],
          "answer": 3
        },
        {
          "id": 4,
          "question": "Which artist recently unveiled a giant sculpture of a uterus outside the Vatican?",
          "options": ["Yayoi Kusama", "Jenny Holzer", "Anish Kapoor", "Kara Walker"],
          "answer": 3
        },
        {
          "id": 5,
          "question": "Who is the creator of the popular manga and anime series 'Demon Slayer'?",
          "options": ["Naoko Takeuchi", "Eiichiro Oda", "Koyoharu Gotouge", "Akira Toriyama"],
          "answer": 2
        },
        {
          "id": 6,
          "question": "Which contemporary artist recently collaborated with Louis Vuitton to design a collection of handbags and accessories?",
          "options": ["Takashi Murakami", "Yayoi Kusama", "Jeff Koons", "Ai Weiwei"],
          "answer": 0
        },
        {
          "id": 7,
          "question": "Which art movement is known for its use of optical illusions and geometric shapes?",
          "options": ["Cubism", "Surrealism", "Pop Art", "Op Art"],
          "answer": 3
        },
        {
          "id": 8,
          "question": "Which museum recently hosted a major retrospective exhibition of the works of Frida Kahlo?",
          "options": ["The Museum of Modern Art (USA)", "Tate Modern (UK)", "The Louvre (France)", "The Victoria and Albert Museum (UK)"],
          "answer": 0
        },
        {
          "id": 9,
          "question": "Who is the British street artist known for his politically charged murals and graffiti art?",
          "options": ["Banksy", "Shepard Fairey", "Invader", "Blek le Rat"],
          "answer": 0
        },
        {
          "id": 10,
          "question": "Which artist recently became the highest-selling living female artist at auction with her painting 'Woman with a Fan'?",
          "options": ["Yayoi Kusama", "Georgia O'Keeffe", "Cecily Brown", "Joan Mitchell"],
          "answer": 2
        }
      ]
    },
    {
      "id": 16,
      "name": "Travel",
      "desc": "Winner announcement: 10:10 PM",
      "imagess": "/img/i16.png",
      "questions": [
        {
          "id": 1,
          "question": "Which city is known as the 'City of Love'?",
          "options": ["Paris", "Rome", "Venice", "Barcelona"],
          "answer": 0
        },
        {
          "id": 2,
          "question": "What is the capital city of Japan?",
          "options": ["Shanghai", "Beijing", "Tokyo", "Seoul"],
          "answer": 2
        },
        {
          "id": 3,
          "question": "Which country is home to the ancient city of Petra?",
          "options": ["Turkey", "Egypt", "Jordan", "Greece"],
          "answer": 2
        },
        {
          "id": 4,
          "question": "Which continent is known for its vast savannas and diverse wildlife, including the 'Big Five'?",
          "options": ["Africa", "South America", "Asia", "Australia"],
          "answer": 0
        },
        {
          "id": 5,
          "question": "What is the tallest mountain in the world?",
          "options": ["Mount Kilimanjaro", "Mount Everest", "Mount McKinley", "Mount Fuji"],
          "answer": 1
        },
        {
          "id": 6,
          "question": "Which country is famous for its beautiful fjords and northern lights?",
          "options": ["Sweden", "Norway", "Denmark", "Iceland"],
          "answer": 1
        },
        {
          "id": 7,
          "question": "Which city is known for its iconic Opera House and Harbour Bridge?",
          "options": ["Sydney", "Melbourne", "Brisbane", "Perth"],
          "answer": 0
        },
        {
          "id": 8,
          "question": "What is the currency of Brazil?",
          "options": ["Euro", "Dollar", "Real", "Peso"],
          "answer": 2
        },
        {
          "id": 9,
          "question": "Which country is home to the famous archaeological site Machu Picchu?",
          "options": ["Mexico", "Peru", "Chile", "Argentina"],
          "answer": 1
        },
        {
          "id": 10,
          "question": "What is the official language of Thailand?",
          "options": ["Thai", "Mandarin", "Vietnamese", "Khmer"],
          "answer": 0
        }
      ]
    }
    
    
    ]