import React from 'react'
import { QuizD } from "../jsonData/QuizDa"
import { Link } from "react-router-dom";
import conis from "../img/conis.png";
import "../css/AllQuiz.css"
import "../css/Home.css"

const AllQuiz = () => {

 
    return (
    
        <div>
          <div className="container3">
          <div className="otte figos">
            <h1 className='figod'>Play Zila 🤩</h1>
            <hr />
            <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center fooet1">
                <Link className='figod22' to={"/About"}>About</Link> 
                <Link className='figod22' to={"/Policy"}>Privacy policy</Link>
               <Link className='figod22' to={"/Contact"}>Contact Us</Link>
                </div>
            </div></div>
            <hr />
            <div className="todo">
            <img className='coco' src={conis} alt="" />
            <h1 className='eana'>500</h1>
            </div>
            <hr />
            <p> There are all Quiz is view in bottom so you can view and run the best Quizzz..</p>
          </div>
     
            <div className="row joko">
            {QuizD.map((quiz) => (
            <div className="dd1" key={quiz.id}>
              <div className="main">
                <div className="allin">
             
              <img className='innerImg' src={quiz.imagess} alt="" />
   
                <div className="little">
                  <p className='heading test'>{quiz.name}</p>
              
                <div className="maja">
                  <p className='waw'>Play safe</p>
                  
                  <p className='waw'>*LIVE</p>
                </div>
                <div className="para">
                  <p className='dex'>{quiz.desc}</p>
                  </div>
                  </div>
                  </div>
                  <div className="butti">
                     {/* redirect PlayQuiz page */}
                  <Link to={`/quiz/${quiz.id}`}>
                    <button className="glow-on-hover">play Quiz</button>
                    </Link>
                  </div>
               
              </div>
            </div>
              ))}
          </div>
          <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center fooet">
                <Link className='figod' to={"/About"}>About</Link> &nbsp;
                &nbsp;<Link className='figod' to={"/Policy"}>Privacy policy</Link>
                &nbsp; <Link className='figod' to={"/Contact"}>Contact Us</Link>

                </div>
            </div></div>
        </div>
        </div>
      );
}

export default AllQuiz