import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { QuizD } from "../jsonData/QuizDa";
import { ScoreData } from "../components/ScoreData";
import "../css/QuizView.css";
import "../css/AllQuiz.css";

const QuizView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const quiz = QuizD.find((quiz) => quiz.id === parseInt(id));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [score, setScore] = useState(0);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [showInfo, setshowInfo] = useState(false);
  let timeoutId; // Declare a variable to hold the timeout ID

  useEffect(() => {
    // Add event listener to detect user interaction
    const handleInteraction = () => {
      clearTimeout(timeoutId); // Clear the timeout when user interacts
    };

    window.addEventListener('mousedown', handleInteraction); // Listen for mouse clicks
    window.addEventListener('keydown', handleInteraction); // Listen for key presses

    return () => {
      // Clean up event listeners when component unmounts
      window.removeEventListener('mousedown', handleInteraction);
      window.removeEventListener('keydown', handleInteraction);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (quizCompleted) {
      // Redirect to the score page after completing all questions
      navigate(`/score/${score}/${quiz.questions.length}/${score}`);
    }
  }, [quizCompleted, score, quiz.questions.length, navigate]);

  const handleOptionChange = (option) => {
    setshowInfo(false);
    setSelectedAnswer(option);
    const currentQuestion = quiz.questions[currentQuestionIndex];
    if (currentQuestion.options[currentQuestion.answer] === option) {
      setScore(score + 1);
    }
    setShowCorrectAnswer(true);
    setshowInfo(true);
    
    timeoutId = setTimeout(() => { // Assign the timeout ID to the variable
      setshowInfo(false);
      if (currentQuestionIndex < quiz.questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedAnswer("");
        setShowCorrectAnswer(false);
      } else {
        setQuizCompleted(true);
      }
    }, 3000); // Timeout set to 20 seconds (20000 milliseconds)
  };

  const renderQuestions = () => {
    const currentQuestion = quiz.questions[currentQuestionIndex];
    return (
      <div>
        <div className="container001" style={{ display: showInfo ? "block" : "none" }}>
          <div className="popup">
            {showCorrectAnswer && (
              <p className="bo">Ans: {currentQuestion.options[currentQuestion.answer]}</p>
            )}
            <br />

            <p>Please wait Some Second...</p>
          </div>
        </div>
        <div className="containerkk">
          <div className="otte figos">
            <h1 className='figod'>Let's get start Quiz 😃</h1>
            <p className="lal">Answer 2 Question then start the Quiz.</p>
          </div>
          <hr />
          <div className="sss">
            <h3 className="hada">{currentQuestion.question}</h3>
            <ul className="yiy">
              {currentQuestion.options.map((option, index) => (
                <li className="iui" key={index} onClick={() => handleOptionChange(option)}>
                  <input
                    type="radio"
                    id={`option${index}`}
                    value={option}
                    checked={option === selectedAnswer}
                    onChange={() => handleOptionChange(option)}
                    disabled={showCorrectAnswer}
                  />
                  <label className="titl" htmlFor={`option${index}`}>{option}</label>
                </li>
              ))}
            </ul>
          </div>
          <div className="row intertext">
            <h1>Play Zila :</h1>
            <p className="raak">Here you have clicked on the Let Start button and all these questions have been answered. <br /> 
            In which you can see total 10 questions here.  Whenever you are a question.  
            <br />After that it will show you total four options below.  In which you can click on any one.  
            <br />When you click on the option.  Immediately you will find their correct answer below.  
            <br />So take special notes which will last you for 5 seconds then you will answer total 10 such questions after which you will see your score board how many correct answers you have given.</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {quizCompleted && <ScoreData score={score} totalQuestions={quiz.questions.length} correctAnswers={score} />}
      {!quizCompleted && currentQuestionIndex < quiz.questions.length && renderQuestions()}
    </div>
  );
}

export default QuizView;
